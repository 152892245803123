import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import {
  type ActionFunctionArgs,
  json,
  type LoaderFunctionArgs,
  type MetaFunction,
} from "@remix-run/node";
import { Form, Link, useActionData, useSearchParams } from "@remix-run/react";
import { HoneypotInputs } from "remix-utils/honeypot/react";
import { z } from "zod";

import { GeneralErrorBoundary } from "#app/components/error-boundary.tsx";
import { CheckboxField, ErrorList, Field } from "#app/components/forms.tsx";
import { StatusButton } from "#app/components/ui/status-button.tsx";
import { login, requireAnonymous } from "#app/utils/auth.server.ts";
import {
  ProviderConnectionForm,
  providerNames,
} from "#app/utils/connections.tsx";
import { checkHoneypot } from "#app/utils/honeypot.server.ts";
import { useIsPending } from "#app/utils/misc.tsx";
import { PasswordSchema, UsernameSchema } from "#app/utils/user-validation.ts";

import { handleNewSession } from "./login.server.ts";

const LoginFormSchema = z.object({
  username: UsernameSchema,
  password: PasswordSchema,
  redirectTo: z.string().optional(),
  remember: z.boolean().optional(),
});

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request);
  return json({});
}

export async function action({ request }: ActionFunctionArgs) {
  await requireAnonymous(request);
  const formData = await request.formData();
  checkHoneypot(formData);
  const submission = await parseWithZod(formData, {
    schema: (intent) =>
      LoginFormSchema.transform(async (data, ctx) => {
        if (intent !== null) return { ...data, session: null };

        const session = await login(data);
        if (!session) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Usuário ou senha inválidos",
          });
          return z.NEVER;
        }

        return { ...data, session };
      }),
    async: true,
  });

  if (submission.status !== "success" || !submission.value.session) {
    return json(
      {
        result: submission.reply({
          hideFields: ["password"],
        }),
      },
      {
        status: submission.status === "error" ? 400 : 200,
      },
    );
  }

  const { session, remember, redirectTo } = submission.value;

  return handleNewSession({
    request,
    session,
    remember: remember ?? false,
    redirectTo,
  });
}

export default function LoginPage() {
  const actionData = useActionData<typeof action>();
  const isPending = useIsPending();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirectTo");
  const reason = searchParams.get("reason");

  const [form, fields] = useForm({
    id: "login-form",
    constraint: getZodConstraint(LoginFormSchema),
    defaultValue: { redirectTo },
    lastResult: actionData?.result,
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: LoginFormSchema });
    },
    shouldRevalidate: "onBlur",
  });

  return (
    <div className="mx-4 flex min-h-full flex-col justify-center pb-32 pt-8 md:mx-0">
      <div className="mx-auto w-full max-w-md rounded-lg bg-white py-4 shadow-md border">
        <div className="flex flex-col gap-3 pb-4 text-center">
          <h1 className="text-h1">Olá!</h1>
          {reason ? (
            <p className="text-body-md text-muted-foreground">
              Faça o login ou crie uma conta gratuita para continuar explorando
              as estações
            </p>
          ) : (
            <p className="text-body-md text-muted-foreground">
              Insira seus dados para entrar
            </p>
          )}
        </div>
        {/* <Spacer size="xs" /> */}

        <div>
          <div className="mx-auto w-full max-w-md px-8">
            <Form method="POST" {...getFormProps(form)}>
              <HoneypotInputs />
              <Field
                labelProps={{ children: "Usuário" }}
                inputProps={{
                  ...getInputProps(fields.username, { type: "text" }),
                  autoFocus: true,
                  className: "lowercase",
                  autoComplete: "username",
                }}
                errors={fields.username.errors}
              />

              <Field
                labelProps={{ children: "Senha" }}
                inputProps={{
                  ...getInputProps(fields.password, {
                    type: "password",
                  }),
                  autoComplete: "current-password",
                }}
                inputType="password"
                errors={fields.password.errors}
              />

              <div className="flex justify-between">
                <CheckboxField
                  labelProps={{
                    htmlFor: fields.remember.id,
                    children: "Lembrar de mim",
                  }}
                  buttonProps={{
                    ...getInputProps(fields.remember, {
                      type: "checkbox",
                    }),
                    defaultChecked: true,
                  }}
                  errors={fields.remember.errors}
                />
                <div>
                  <Link
                    to="/recuperar-senha"
                    className="text-body-xs font-semibold"
                  >
                    Esqueceu sua senha?
                  </Link>
                </div>
              </div>

              <input
                {...getInputProps(fields.redirectTo, { type: "hidden" })}
              />
              <ErrorList errors={form.errors} id={form.errorId} />

              <div className="flex items-center justify-between gap-6 pt-3">
                <StatusButton
                  className="w-full font-bold"
                  status={isPending ? "pending" : (form.status ?? "idle")}
                  type="submit"
                  disabled={isPending}
                >
                  Entrar
                </StatusButton>
              </div>
            </Form>
            <ul className="mt-5 flex flex-col gap-5 border-b-2 border-t-2 border-border py-3">
              {providerNames.map((providerName) => (
                <li key={providerName}>
                  <ProviderConnectionForm
                    type="Login"
                    providerName={providerName}
                    redirectTo={redirectTo}
                  />
                </li>
              ))}
            </ul>
            <div className="flex items-center justify-center gap-2 pt-6 md:pt-4">
              <span className="text-muted-foreground">Novo por aqui?</span>
              <Link
                to={
                  redirectTo
                    ? `/criar-conta?${encodeURIComponent(redirectTo)}`
                    : "/criar-conta"
                }
              >
                <span className="font-bold hover:underline">
                  Criar uma conta
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const meta: MetaFunction = () => [
  { title: "Login | Carregados" },
  {
    name: "description",
    content:
      "3.000+ carros elétricos anunciados das 20+ principais marcas. Anúncie para um público específico e engajado que busca ativamente veículos elétricos.",
  },
  { itemProp: "name", content: "Carregados - Marketplace de Carros Elétricos" },
  {
    itemProp: "description",
    content:
      "3.000+ carros elétricos anunciados das 20+ principais marcas. Anúncie para um público específico e engajado que busca ativamente veículos elétricos.",
  },
  { itemProp: "image", content: "https://carregados.com.br/img/og-main.jpg" },
  { property: "og:url", content: "https://carregados.com.br/login" },
  { property: "og:type", content: "website" },
  {
    property: "og:title",
    content: "Carregados - Marketplace de Carros Elétricos",
  },
  {
    property: "og:description",
    content:
      "3.000+ carros elétricos anunciados das 20+ principais marcas. Anúncie para um público específico e engajado que busca ativamente veículos elétricos.",
  },
  {
    property: "og:image",
    content: "https://carregados.com.br/img/og-main.jpg",
  },
  { name: "twitter:card", content: "summary_large_image" },
  {
    name: "twitter:title",
    content: "Carregados - Marketplace de Carros Elétricos",
  },
  {
    name: "twitter:description",
    content:
      "3.000+ carros elétricos anunciados das 20+ principais marcas. Anúncie para um público específico e engajado que busca ativamente veículos elétricos.",
  },
  {
    name: "twitter:image",
    content: "https://carregados.com.br/img/og-main.jpg",
  },
  {
    name: "twitter:image:alt",
    content: "Carregados - Marketplace de Carros Elétricos",
  },
  {
    tagName: "link",
    rel: "canonical",
    href: "https://carregados.com.br/login",
  },
];
export function ErrorBoundary() {
  return <GeneralErrorBoundary />;
}
